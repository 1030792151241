import { useState, useEffect } from "react";
import styled from "styled-components";

export const TwoColumnView = ({ manifestData, config }: any) => {
  const [twoColumnData, setTwoColumnData] = useState<any>();
  // const colors = manifestData?.['stylesheet']['colors'];
  const url = manifestData?.["views"]["twoColumnView"]["url"];

  useEffect(() => {
    const fetchTwoColumnData = async () => {
      //This data is from a page in the control panel
      try {
        const response = await fetch(url);
        const data = await response.json();
        setTwoColumnData(data.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchTwoColumnData();
  }, [url]);

  return (
    <TwoColumnContainer $bgColor={config?.bgColor}>
      <Header
        dangerouslySetInnerHTML={{
          __html: twoColumnData?.title,
        }}
      />
      <FlexBox>
        <TextBox>
          <Text
            dangerouslySetInnerHTML={{
              __html: twoColumnData?.content,
            }}
          />
        </TextBox>
        <ImageBox>
          <Image src={twoColumnData?.images[0].large} />
        </ImageBox>
      </FlexBox>
    </TwoColumnContainer>
  );
};

export const TwoColumnContainer = styled.section<{ $bgColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  flex-wrap: wrap;
  background-color: ${({ $bgColor }) => $bgColor};
  flex-grow: 2;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const TextBox = styled.div`
  width: 500px;
`;

export const Header = styled.h2`
  text-align: center;
  font-size: 2.25rem;
  font-family: Fjalla One, sans;
  text-transform: uppercase;

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 1.1rem;
  margin: 1rem 0;

  @media screen and (max-width: 900px) {
    font-size: 0.8rem;
  }
`;

export const ImageBox = styled.div`
  width: 500px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`;

export const Image = styled.img`
  max-height: 16rem;
  position: relative;
`;
