import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import BlogListWidget from "./BlogListWidget";
import OCVBlogDetail from "../OCVBlog/OCVBlogDetail/OCVBlogDetail";
import axios from "axios";

export default withRouter(function BlogPost(props: any) {
  const [data, setData] = React.useState<any>({});
  const { route, manifestData, sortOrder, showSort, hideTime, manifest } =
    props;
  const [page, setPage] = React.useState<number>(1);
  const [previousQuery, setPreviousQuery] = React.useState<string>("");
  const [totalEntries, setTotalEntries] = React.useState<number>(1);
  const [query, setQuery] = React.useState<string>("");
  const [limit, setLimit] = React.useState<number>(manifestData.config.limit);
  const [sort, setSort] = React.useState("");

  let appID = manifestData.config.appID;
  let type = manifestData.config.type;
  //let sort = manifestData.config.sort;
  let blogKey = manifestData.config.blogKey;
  let displayDate = manifestData.config.displayDate;
  let truncate = manifestData.config.truncation;

  let totalPages = Math.ceil(totalEntries / limit);

  function checkPageLeft() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }

  function checkPageRight() {
    if (page !== totalPages - 1) {
      setPage(page + 1);
    }
  }

  useEffect(() => {
    sortOrder ? setSort(sortOrder) : setSort("dateDesc");
  }, [sortOrder]);

  useEffect(() => {
    setPreviousQuery(query);
    if (query === "") {
      axios
        .get(
          `https://okhugm75f7.execute-api.us-east-1.amazonaws.com/dev/paginatedBlog/${appID}?blogKey=${blogKey}&limit=${limit}&sort=${sort}&type=${type}&page=${page}`
        )
        .then((response: any) => {
          setData(response.data);
          setTotalEntries(response.data.pagination.totalEntries);
          setLimit(manifestData.config.limit);
          if (query !== previousQuery) setPage(1);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      axios
        .get(
          `https://okhugm75f7.execute-api.us-east-1.amazonaws.com/dev/paginatedSearch/${appID}?blogKey=${blogKey}&limit=${limit}&q=${query}&type=${type}&page=${page}`
        )
        .then((response: any) => {
          setData(response.data);
          setTotalEntries(response.data.pagination.totalEntries);
          setLimit(manifestData.config.limit);
          if (query !== previousQuery) setPage(1);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [appID, blogKey, limit, manifestData.config.limit, page, previousQuery, query, sort, sortOrder, type]);

  return (
    <Switch>
      <Route exact path='/' render={() => <Redirect to='/' />} />
      <Route
        exact
        path={"/" + route}
        render={() => (
          <BlogListWidget
            anchorID={props.anchorID}
            route={route}
            manifestData={manifestData}
            handlePageLeft={() => checkPageLeft()}
            handlePageRight={() => checkPageRight()}
            totalEntries={totalEntries}
            limit={limit}
            data={data}
            sortOrder={sortOrder}
            setSort={setSort}
            showSort={showSort}
            query={query}
            setQuery={setQuery}
            displayDate={displayDate}
            page={page}
            setPage={setPage}
            hideTime={hideTime}
            truncate={truncate}
          />
        )}
      />
      {data.entries && data.entries.length !== 0 ? (
        <Route
          path={"/" + route + "/:id"}
          render={() => (
            <OCVBlogDetail
              manifest={manifest}
              subtypes={[]}
              blogEntries={
                data.entries && data.entries.length > 0 ? data.entries : []
              }
              route={route}
            />
          )}
        />
      ) : (
        ""
      )}
    </Switch>
  );
});
