import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SocialBar from "../SocialBar/SocialBar";
import { NominateButton } from "../../OCVFeatures/MonthlyFeature/MonthlyFeature";

interface IConfig {
  divideColor: string;
  additionalTailwindStyles: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  buttonBorderColor: string;
  displayDate: boolean;
  bgColor: string;
  socialBar: boolean;
  socialBarName: string;
}

interface IViewData {
  feature: string;
  type: "newsDigest";
  config: IConfig;
}

interface INewsDigest {
  viewData: IViewData;
  manifestData: any;
  anchorID: string;
}

interface DigestImage {
  small?: string;
  large?: string;
}

interface BlogID {
  $id: string;
}

interface BlogDate {
  sec: number;
  usec: number;
}

interface ICalendar {
  epoch: number;
  type: 4;
  feedID: number;
  origin: number;
  eventID: string;
  calendarID: string;
  calendarTitle: string;
  title: string;
  content: string;
  summary: string;
  images: DigestImage[];
}

interface ITwitter {
  epoch: number;
  type: 2;
  feedID: number;
  origin: number;
  /**
   * The @ of the twitter user
   */
  title: string;
  /**
   * Content of twitter post. Can contain HTML prefer to use summary if possible
   */
  content: string;
  /**
   * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
   */
  summary: string;
  images: DigestImage[];
  /**
   * Profile image of twitter user. Used if tweet does not contain images.
   */
  profile_image_url_https: string;
  /**
   * ID of specific post. Append to link after the @ for the twitter user (remove @ symbol) and add /status/ between the two. EX: https://twitter.com/ocvtwitter/status/12341235145413
   */
  id_str: string;
  link: string;
}

interface IFacebook {
  epoch: number;
  type: 3;
  feedID: number;
  origin: number;
  /**
   * The PageID and PostID are contained in this key. UserID is before underscore. PostID is after underscore. EX: 109290340297987_0190234578001293. Use like this: https://facebook.com/109290340297987_0190234578001293
   */
  facebookPostID: string;
  /**
   * Facebook page/user title
   */
  title: string;
  /**
   * Content of twitter post. Can contain HTML prefer to use summary if possible
   */
  content: string;
  /**
   * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
   */
  summary: string;
  /**
   * Profile image of facebook page. Used if there are no images in the post.
   */
  fb_profile_image: string;
  status_type: string;
  images: DigestImage[];
}

interface IBlog {
  _id: BlogID;
  /**
   * Email of blog post creator.
   */
  creator: string;
  date: BlogDate;
  /**
   * Title of blog post.
   */
  title: string;
  /**
   * Content of blog post. Can contain HTML prefer to use summary.
   */
  content: string;
  /**
   * Different ID than the _id: { $id: number }
   */
  blogID: number;
  images: DigestImage[];
  status: number;
  epoch: number;
  type: 1;
  feedID: number;
  origin: number;
  /**
   * Summary of blog post
   */
  summary: string;
  /**
   * Title of the blog that this post came from
   */
  blogTitle: string;
  blogIdentifier: string;
}

interface IPush {
  epoch: number;
  type: 5;
  feedID: number;
  origin: number;
  /**
   * Title of push notification
   */
  title: string;
  channel: string;
  /**
   * Title of channel for push notification.
   */
  channelTitle: string;
  /**
   * Content of twitter post. Can contain HTML prefer to use summary if possible
   */
  content: string;
  /**
   * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
   */
  summary: string;
  images: DigestImage[];
}

type DigestFeed = Array<ITwitter | IFacebook | IBlog | IPush | ICalendar>;

export default function NewsDigest({
  viewData,
  anchorID,
  manifestData,
}: INewsDigest) {
  const [digest, setDigest] = useState<DigestFeed>();
  const config = manifestData?.["views"]["newsDigest"]["config"];
  useEffect(() => {
    fetch(manifestData?.features[viewData["feature"]]["url"], {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setDigest(data);
      });
  }, [manifestData?.features, viewData]);

  return (
    <div
      id={anchorID}
      className={`gap-4 flex flex-col place-items-center h-full w-full py-12 px-6 sm:px-8 md:px-12 lg:px-36 ${viewData?.config?.additionalTailwindStyles}`}
      style={{ backgroundColor: viewData?.config?.bgColor }}
    >
      <h2
        style={{
          fontFamily: "Fjalla One, sans-serif",
          color: viewData?.config?.buttonTextColor,
        }}
        className={`m-auto text-2xl md:text-3xl lg:text-4xl tracking-tight font-extrabold uppercase`}
      >
        {manifestData?.features[viewData["feature"]]?.title}{" "}
      </h2>
      {viewData?.config?.socialBar && (
        <SocialBar
          viewData={manifestData?.views[viewData?.config?.socialBarName]}
        />
      )}
      <div className='grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-2 sm:gap-4 md:gap-6 lg:gap-8 mt-12'>
        {digest
          ?.slice(0, 6)
          .sort((a, b) => b.epoch - a.epoch)
          .map((item: ITwitter | IFacebook | IBlog | IPush | ICalendar) => (
            <div key={item?.epoch} className='w-full h-auto flex flex-col'>
              <Link
                to={`/${viewData?.feature.replace(/\s+/g, "")}/${
                  item?.type === 1
                    ? item?._id?.$id
                    : item?.type === 2
                    ? item?.id_str
                    : item?.type === 3
                    ? item?.facebookPostID
                    : item?.type === 4
                    ? item?.eventID
                    : item?.epoch
                }`}
                className='w-full h-auto appearance-none outline-none bg-transparent hover:no-underline flex flex-col'
              >
                <div className='bg-white overflow-hidden rounded-lg mx-2 border-2 border-gray-100'>
                  <div className='relative rounded-lg bg-white px-1 py-1 mb-1 flex items-center space-x-1'>
                    <div className='px-2 py-2 sm:px-4'>
                      {item.type === 1 && (
                        <FontAwesomeIcon
                          size='3x'
                          color='#7a7a7a'
                          icon={["far", "newspaper"]}
                        />
                      )}
                      {item.type === 2 && (
                        <FontAwesomeIcon
                          size='3x'
                          color='#1DA1F2'
                          icon={["fab", "twitter-square"]}
                        />
                      )}
                      {item.type === 3 && (
                        <FontAwesomeIcon
                          size='3x'
                          color='#4267B2'
                          icon={["fab", "facebook-square"]}
                        />
                      )}
                      {item.type === 4 && (
                        <FontAwesomeIcon
                          size='3x'
                          color='#7a7a7a'
                          icon={["far", "calendar-alt"]}
                        />
                      )}
                      {item.type === 5 && (
                        <FontAwesomeIcon
                          size='3x'
                          color='#7a7a7a'
                          icon={["far", "bell"]}
                        />
                      )}
                    </div>
                    <div className='flex-1 min-w-0'>
                      {item.type === 1 && (
                        <div>
                          <p className='text-base font-semibold text-gray-900'>
                            {item.blogTitle}
                          </p>
                          <p className='text-base text-gray-500 truncate'>
                            New Post
                          </p>
                        </div>
                      )}
                      {item.type === 2 && (
                        <div>
                          <p className='text-base font-semibold text-gray-900'>
                            Twitter
                          </p>
                          <p className='text-base text-gray-500 truncate'>
                            {item.title}
                          </p>
                        </div>
                      )}
                      {item.type === 3 && (
                        <div>
                          <p className='text-base font-semibold text-gray-900'>
                            Facebook
                          </p>
                          <p className='text-base text-gray-500 truncate'>
                            {item.title}
                          </p>
                        </div>
                      )}
                      {item.type === 4 && (
                        <div>
                          <p className='text-base font-semibold text-gray-900'>
                            {item.calendarTitle}
                          </p>
                          <p className='text-base text-gray-500 truncate'>
                            {item.title}
                          </p>
                        </div>
                      )}
                      {item.type === 5 && (
                        <div>
                          <p className='text-base font-semibold text-gray-900'>
                            Notification
                          </p>
                          <p className='text-base text-gray-500 truncate'>
                            {item.channelTitle} - {item.channel}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='flex flex-col text-left px-3 mb-3'>
                    {item?.images?.length > 0 ? (
                      item?.images[0]?.large ? (
                        <>
                          <div
                            style={{
                              backgroundImage: `url(${item.images[0].large})`,
                            }}
                            className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                          ></div>
                          {item.type === 2 ||
                          item.type === 3 ||
                          item.type === 4 ? (
                            <p
                              className='mt-3 text-base font-medium text-gray-800 break-words'
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></p>
                          ) : (
                            <div className='flex flex-col space-y-1'>
                              <p className='mt-3 text-base font-semibold text-gray-900'>
                                {item.title}
                              </p>
                              <p
                                className='mt-2 text-base font-medium text-gray-800 break-words'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.summary.length === 200
                                      ? `${item.summary}...`
                                      : item.summary,
                                }}
                              ></p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              backgroundImage: `url(${item.images[0].small})`,
                            }}
                            className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                          ></div>
                          {item.type === 2 ||
                          item.type === 3 ||
                          item.type === 4 ? (
                            <p
                              className='mt-3 text-base font-medium text-gray-800 break-words'
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></p>
                          ) : (
                            <div className='flex flex-col space-y-1'>
                              <p className='mt-3 text-base font-semibold text-gray-900'>
                                {item.title}
                              </p>
                              <p
                                className='mt-2 text-base font-medium text-gray-800 break-words'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.summary.length === 200
                                      ? `${item.summary}...`
                                      : item.summary,
                                }}
                              ></p>
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      <div>
                        {item.type === 2 && (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${item?.profile_image_url_https})`,
                              }}
                              className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                            ></div>
                            <p
                              className='mt-3 text-base font-medium text-gray-800 break-words'
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></p>
                          </>
                        )}
                        {item.type === 3 && (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${item?.fb_profile_image})`,
                              }}
                              className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                            ></div>
                            <p
                              className='mt-3 text-base font-medium text-gray-800 break-words'
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></p>
                          </>
                        )}
                        {item.type === 4 && (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${manifestData?.stylesheet?.images?.sheriffPatch?.url})`,
                              }}
                              className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                            ></div>
                            <p
                              className='mt-3 text-base font-medium text-gray-800 break-words'
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.summary.length === 200
                                    ? `${item.summary}...`
                                    : item.summary,
                              }}
                            ></p>
                          </>
                        )}
                        {item.type === 1 && (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${manifestData?.stylesheet?.images?.sheriffPatch?.url})`,
                              }}
                              className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                            ></div>
                            <div className='flex flex-col space-y-1'>
                              <p className='mt-3 text-base font-semibold text-gray-900'>
                                {item.title}
                              </p>
                              <p
                                className='mt-2 text-base font-medium text-gray-800 break-words'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.summary.length === 200
                                      ? `${item.summary}...`
                                      : item.summary,
                                }}
                              ></p>
                            </div>
                          </>
                        )}
                        {item.type === 5 && (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${manifestData?.stylesheet?.images?.sheriffPatch?.url})`,
                              }}
                              className='w-full sm:max-h-60 max-h-48 min-h-full sm:h-60 h-48 overflow-none rounded-sm bg-center bg-no-repeat bg-contain bg-clip-border '
                            ></div>
                            <div className='flex flex-col space-y-1'>
                              <p className='mt-3 text-base font-semibold text-gray-900'>
                                {item.title}
                              </p>
                              <p
                                className='mt-2 text-base font-medium text-gray-800 break-words'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.summary.length === 200
                                      ? `${item.summary}...`
                                      : item.summary,
                                }}
                              ></p>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  {viewData?.config?.displayDate && (
                    <div className='flex flex-row px-1 py-2 justify-between border-t border-gray-200 mx-2'>
                      <p className=' text-sm text-gray-500'>
                        {new Date(item.epoch * 1000).toLocaleString([], {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          ))}
      </div>
      <div className='mt-8'>
        <NominateButton
          buttonBgColor={config?.buttonBackgroundColor}
          buttonTextColor={config?.buttonTextColor}
          to={`/${viewData?.feature.replace(/\s+/g, "")}`}
        >
          {config?.buttonText}
        </NominateButton>
      </div>
    </div>
  );
}
